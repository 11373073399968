// You can specify which plugins you need
import {Tooltip, Toast, Popover} from 'bootstrap';

import './mmenu/index.js';
import './splide/index.js';
import './headerScroll/index.js';
import './clickNavigation/index.js';
import ModalHandler from './modalHandler/ModalHandler';


(function () {
    const modalHandler = new ModalHandler('js-contact-box');
})()





